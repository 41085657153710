import { useRef, useState } from "react";
import EditItem from "../../common/EditItem";
import {
  ITenant,
  createTenant,
  editTenant,
  getTenant,
} from "../../services/assetServices";
import { v4 as uuid } from "uuid";
import { useBoolean } from "@fluentui/react-hooks";
import UserOrGroupSelector from "./UsersOrGroupSelectors";
import {
  DetailsList,
  IconButton,
  SelectionMode,
  Stack,
  Text,
} from "@fluentui/react";
import OtherInfo from "../../common/OtherInfo";
import { useSetAtom } from "jotai";
import { successMessageAtom } from "../../atoms/messageBarAtoms";

interface IUserOrGroup {
  id: number;
  isGroup: boolean;
  username?: string;
  name?: string;
}

const Tenant = () => {
  const [
    isAccessSelectorOpen,
    { setTrue: showAccessSelector, setFalse: hideAccessSelector },
  ] = useBoolean(false);
  const [tenantOtherInfo, setTenantOtherInfo] = useState<string>("{}");
  const [accesses, setAccesses] = useState<IUserOrGroup[]>();
  const isSaved = useRef(false);
  const setSuccessMessage = useSetAtom(successMessageAtom);

  return (
    <>
      <Text variant="xLarge">Tenant</Text>

      <EditItem
        getAction={async (abortController, id) => {
          const tenant = await getTenant(abortController, id);
          setTenantOtherInfo(tenant.otherInfo);
          setAccesses(
            tenant.users
              ?.map((user) => ({
                id: user.id,
                isGroup: false,
                name: user.username,
              }))
              .concat(
                tenant.groups?.map((group) => ({
                  id: group.id,
                  isGroup: true,
                  name: group.name,
                })) ?? []
              )
          );
          return tenant;
        }}
        newAction={async (abortController, newTenant) => {
          const tenant = await createTenant(abortController, {
            name: newTenant.name,
            description: newTenant.description,
            uuid: newTenant.uuid,
            usersIds: accesses
              ?.filter((access) => !access.isGroup)
              .map((access) => access.id),
            groupsIds: accesses
              ?.filter((access) => access.isGroup)
              .map((access) => access.id),
            otherInfo: tenantOtherInfo,
          });
          setSuccessMessage(`Tenant with id: ${tenant.tenantId} created.`);
        }}
        editAction={async (abortController, id, editedTenant) => {
          await editTenant(abortController, id, {
            name: editedTenant.name,
            description: editedTenant.description,
            usersIds: accesses
              ?.filter((access) => !access.isGroup)
              .map((access) => access.id),
            groupsIds: accesses
              ?.filter((access) => access.isGroup)
              .map((access) => access.id),
            otherInfo: tenantOtherInfo,
          });
          setSuccessMessage(`Tenant with id: ${id} successfully updated.`);
        }}
        isSaved={isSaved}
        back={"/security?tab=tenants"}
        preButtons={[
          {
            text: "Access",
            iconProps: { iconName: "UserFollowed" },
            onClick: () => showAccessSelector(),
          },
        ]}
        defaultValues={JSON.stringify({ uuid: uuid() })}
        metadata={{
          fields: [
            {
              name: "name",
              fieldType: "String",
              label: "Name",
            },
            {
              name: "description",
              fieldType: "String",
              label: "Description",
            },
            {
              name: "uuid",
              fieldType: "String",
              label: "Unique Id",
            },
          ],
          lookups: [],
          validations: [],
        }}
      >
        <Stack styles={{ root: { width: 450 } }}>
          <Text variant="xLarge">OtherInfo</Text>
          <OtherInfo
            metadata={{
              fields: [
                {
                  name: "logo",
                  fieldType: "String",
                  label: "Logo",
                },
                {
                  name: "languages",
                  fieldType: "Lookup",
                  multiSelect: true,
                  label: "Languages",
                  lookupList: "Languages",
                },
              ],
              lookups: [
                {
                  name: "Languages",
                  values: [
                    { key: "EN", value: "English" },
                    { key: "DE", value: "Germany" },
                    { key: "CN", value: "Chinese" },
                  ],
                },
              ],
              validations: [],
            }}
            otherInfo={tenantOtherInfo ?? "{}"}
            isSaved={isSaved}
            onOtherInfoChanged={(newValue: string) => {
              setTenantOtherInfo(JSON.stringify(newValue));
            }}
            hideTitle
          />
          <Text variant="xLarge">Permission</Text>
          <DetailsList
            items={accesses ?? []}
            styles={{ root: { width: 525 } }}
            selectionMode={SelectionMode.none}
            columns={[
              {
                fieldName: "name",
                key: "Username",
                name: "User|Group Name",
                minWidth: 220,
              },
              {
                fieldName: "id",
                key: "Id",
                name: "User|Group Id",
                minWidth: 110,
                onRender(item, index, column) {
                  return (
                    <Stack horizontal grow={true} tokens={{ childrenGap: 5 }}>
                      <Text variant="large">{item.id}</Text>
                      <Text variant="small">
                        {item.isGroup ? `(Group)` : `(User)`}
                      </Text>
                    </Stack>
                  );
                },
              },
              {
                fieldName: "isGroup",
                key: "IsGroup",
                name: "IsGroup",
                minWidth: 50,
              },
              {
                key: "Action",
                name: "",
                minWidth: 50,
                onRender: (item?: any) => (
                  <IconButton
                    iconProps={{ iconName: "Delete" }}
                    onClick={() =>
                      setAccesses([
                        ...(accesses?.filter(
                          (access) => access.id !== item.id
                        ) ?? []),
                      ])
                    }
                  />
                ),
              },
            ]}
          />
        </Stack>
      </EditItem>
      <UserOrGroupSelector
        isOpen={isAccessSelectorOpen}
        onUserOrGroupSelected={(
          id: number,
          isGroup: boolean,
          username?: string
        ) => {
          setAccesses([...(accesses ?? []), { id, isGroup, username }]);
        }}
        hideUsersAndGroupsSelector={hideAccessSelector}
      />
    </>
  );
};

export default Tenant;
