import { useAccount, useIsAuthenticated, useMsal } from "@azure/msal-react";
import {
  CommandBar,
  ICommandBarItemProps,
  INavLinkGroup,
  INavStyles,
  Nav,
  Text,
  Stack,
  ITextStyles,
  mergeStyles,
  MessageBar,
  ProgressIndicator,
  IProgressIndicatorStyles,
  INavLink,
  MessageBarButton,
  Separator,
  SpinnerSize,
  Spinner,
  MessageBarType,
} from "@fluentui/react";
import {
  HubConnection,
  HubConnectionBuilder,
  HubConnectionState,
} from "@microsoft/signalr";
import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { loginRequest } from "../authConfig";
import {
  IAssetCategory,
  INotifMessage,
  authorize,
  getAssetCategories,
  getBuildNumber,
  getZones,
} from "../services/assetServices";
import { getProfileData } from "../services/graph";
import AppContext from "./AppContext";
import { useBoolean } from "@fluentui/react-hooks";
import InfoDialog from "../common/InfoDialog";
import logo from "../assets/MoKoLogo.svg";
import ChatCommandDialog from "./ChatCommandDialog";
import MiniChart from "./charts/MiniChart";
import { Actions, canDo } from "../actions";
import { useAtom, useAtomValue, useSetAtom } from "jotai";
import {
  clearMessageAtom,
  errorMessageAtom,
  isInProgressAtom,
  messageAtom,
  messageBarAtom,
} from "../atoms/messageBarAtoms";
import {
  isAuthenticatedAtom,
  profileDataAtom,
  IProfileData,
  currentTenantNameAtom,
} from "../atoms/authAtoms";
import MyPermissions from "./MyPermissions";
import TenantSelector from "./TenantSelector";
import { notifAtom, notificationsAtom } from "../atoms/notifAtoms";
import { idp, idps } from "../App";
import Notifications from "./Notifications";
import { flattenCategoriesTree, flattenZonesTree } from "../common/FlattenHelper";
import { assetCategoriesAtom } from "../atoms/assetCategoriesAtom";
import { zonesAtom, zonesLoadedAtom } from "../atoms/zonesAtom";

const Home = (props: any) => {
  let navigate = useNavigate();
  const context = useContext(AppContext);
  const message = useAtomValue(messageAtom);
  const setErrorMessage = useSetAtom(errorMessageAtom);
  const clearMessage = useSetAtom(clearMessageAtom);
  const [profileData, setProfileData] = useAtom(profileDataAtom);
  const [isInProgress, setIsInProgress] = useAtom(isInProgressAtom);
  const [notifications, setNotifications] = useAtom(notificationsAtom);
  const [assetCategories, setAssetCategories] = useAtom(assetCategoriesAtom);
  const [zones, setZones] = useAtom(zonesAtom);
  const [zonesLoaded, setZonesLoaded] = useAtom(zonesLoadedAtom);

  const { instance, accounts } = useMsal();
  const isAuthenticated = useIsAuthenticated();

  const [navExpanded, setNavExpanded] = useState(true);
  const [activeNav, setActiveNav] = useState("home");

  const _items: ICommandBarItemProps[] = [];

  const [
    isAboutModalOpen,
    { setTrue: showAboutModal, setFalse: hideAboutModal },
  ] = useBoolean(false);
  const [hideDialog, { toggle: toggleHideDialog }] = useBoolean(true);
  const [
    isNotificationsOpen,
    { setTrue: showNotifications, setFalse: hideNotifications },
  ] = useBoolean(false);

  const [expandedNav, setExpandedNav] = useState("");

  const [buildNumber, setBuildNumber] = useState("");

  const handleSignIn = () => {
    instance.loginRedirect(loginRequest).catch((e) => {
      console.error(e);
    });
  };

  const handleAboutClick = () => {
    const abortController = new AbortController();
    getBuildNumber(abortController)
      .then((buildNumber: string) => {
        setBuildNumber(buildNumber);
        showAboutModal();
      })
      .catch((reason: any) => {
        console.log(reason);
        setBuildNumber("Failed to fetch API Build number");
        showAboutModal();
      });
  };

  const [showAuthSpinner, setShowAuthSpinner] = useState(false);

  const account = useAccount();
  useEffect(() => {
    const fetchAuth = async () => {
      setShowAuthSpinner(true);
      if (idp === idps.DB) {
        return;
      }
      const abortController = new AbortController();
      try {
        setIsInProgress(true);

        const profileData: IProfileData = await getProfileData(
          instance,
          accounts
        );
        const username = account?.username;
        profileData.userPrincipalName = username ?? "";
        const authorizeResponse = await authorize(abortController, {
          username: profileData.userPrincipalName,
        });
        profileData.permissions = authorizeResponse.permissions;
        profileData.tenants = authorizeResponse.tenants;
        profileData.currentTenant = authorizeResponse.currentTenant;
        setProfileData(profileData);
      } catch (error: any) {
        console.error("Error:", error);
        setErrorMessage(
          "Fail at authentication. Please try again later and if problem persisted contact support."
        );
      } finally {
        setIsInProgress(false);
        setShowAuthSpinner(false);
      }
      return () => {
        abortController.abort();
      };
    };

    fetchAuth();
  }, [
    accounts,
    instance,
    profileData.currentTenant,
    setErrorMessage,
    setIsInProgress,
    setProfileData,
  ]);

  const [isConnectedToHub, setIsConnectedToHub] = useState(false);
  const currentTenantName = useAtomValue(currentTenantNameAtom);

  const [
    isMyPermissionsOpen,
    { setTrue: showMyPermissions, setFalse: hideMyPermissions },
  ] = useBoolean(false);
  const [
    isTenantSelectorOpen,
    { setTrue: showTenantSelector, setFalse: hideTenantSelector },
  ] = useBoolean(false);

  const _farItems: ICommandBarItemProps[] = [
    {
      key: "tenant",
      text: currentTenantName,
    },
    {
      key: "chatCommand",
      text: "Chat to Command",
      ariaLabel: "Chat to Command",
      iconOnly: true,
      iconProps: { iconName: "ChatBot" },
      onClick: (ev) => toggleHideDialog(),
    },
    {
      key: "connectionStatus",
      text: "Connection Status",
      ariaLabel: "Connection Status",
      iconOnly: true,
      iconProps: {
        iconName: isConnectedToHub ? "PlugConnected" : "PlugDisconnected",
      },
    },
    {
      key: "notifications",
      text:
        notifications.length > 0 ? notifications.length.toString() : undefined,
      ariaLabel: "Notifications",
      buttonStyles: {
        textContainer: {
          position: "absolute",
          left: 3,
          top: 5,
          backgroundColor: "red",
          color: "white",
          borderRadius: 5,
        },
      },
      iconProps: { iconName: "Ringer" },
      onClick: () => showNotifications(),
    },
    !(isAuthenticated || isAuthenticatedAtom)
      ? {
          key: "login",
          text: "Login",
          ariaLabel: "Login",
          iconOnly: true,
          iconProps: { iconName: "FollowUser" },
          onClick: handleSignIn,
        }
      : {
          key: "signedIn",
          text: `${profileData.givenName ?? ""} ${profileData.surname ?? ""}`,
          // This needs an ariaLabel since it's icon-only
          ariaLabel: "Signed In",
          iconOnly: true,
          iconProps: { iconName: "Contact" },
          subMenuProps: {
            items: [
              {
                key: "profile",
                text: "Profile",
                ariaLabel: "Profile",
                onClick: async () => {
                  context.setIsProfileDialogOpen(true);
                },
              },
              {
                key: "permissions",
                text: "My permissions",
                ariaLabel: "Permissions",
                onClick: async () => {
                  showMyPermissions();
                },
              },
              {
                key: "tenants",
                text: "Tenant",
                ariaLabel: "Tenant",
                onClick: async () => {
                  showTenantSelector();
                },
              },
              {
                key: "logout",
                text: "Logout",
                onClick: () => {
                  if (idp === idps.AAD) {
                    instance.logoutRedirect().catch((e) => {
                      console.error(e);
                    });
                  } else if (idp === idps.DB) {
                    localStorage.removeItem("token");
                    window.location.reload();
                  }
                },
              },
              {
                key: "about",
                text: "About",
                ariaLabel: "About",
                onClick: handleAboutClick,
              },
            ],
          },
        },
  ];

  const navLinkGroups: INavLinkGroup[] = [
    {
      links: [
        {
          name: "",
          url: "",
          icon: navExpanded ? "DoubleChevronLeft8" : "DoubleChevronRight8",
          onClick: () => {
            if (navExpanded) {
              setNavExpanded(false);
            } else {
              setNavExpanded(true);
            }
          },
        },
        {
          name: "Assets",
          url: "/",
          expandAriaLabel: "Assets list",
          collapseAriaLabel: "Assets list",
          iconProps: {
            iconName: "CubeShape", //"ButtonControl",
            styles: { root: { color: "green" } },
          },
          key: "assets",
          disabled: !canDo(profileData, Actions.ManageAssets),
        },
        {
          name: "Zones",
          url: "/zones",
          expandAriaLabel: "Zones list",
          collapseAriaLabel: "Zones list",
          iconProps: {
            iconName: "EngineeringGroup",
            styles: { root: { color: "goldenRod" } },
          },
          key: "zones",
          disabled: !canDo(profileData, Actions.ManageZones),
        },
        {
          name: "Categories",
          url: "/categories",
          expandAriaLabel: "Asset Categories",
          collapseAriaLabel: "Asset Categories",
          iconProps: {
            iconName: "GroupList",
            styles: { root: { color: "darkMagenta" } },
          },
          key: "categories",
          disabled: !canDo(profileData, Actions.ManageAssetsCategories),
        },
        {
          name: "Contractors",
          url: "/contractors",
          expandAriaLabel: "Contractors",
          collapseAriaLabel: "Contractors",
          iconProps: {
            iconName: "AccountManagement",
            styles: { root: { color: "darkGoldenRod" } },
          },
          key: "contractors",
          disabled: !canDo(profileData, Actions.ManageContractors),
        },
        {
          name: "Issues",
          url: "/issues",
          iconProps: {
            iconName: "IssueSolid",
            styles: { root: { color: "salmon" } },
          },
          key: "issues",
          disabled: !canDo(profileData, Actions.ManageIssues),
        },
        {
          name: "Work Orders",
          url: "/workOrders",
          iconProps: {
            iconName: "WorkItem",
            styles: { root: { color: "mediumBlue" } },
          },
          key: "workOrders",
          isExpanded: false,
          disabled: !canDo(profileData, Actions.ManageWorkOrders),
        },
        {
          name: "QR Codes",
          url: "/qrCodes",
          iconProps: {
            iconName: "QRCode",
            styles: { root: { color: "black", backgroundColor: "white" } },
          },
          key: "qrCodes",
        },
        {
          name: "Workflows",
          url: "/workflows",
          iconProps: {
            iconName: "FlowChart",
            styles: { root: { color: "dodgerBlue" } },
          },
          key: "workflows",
          disabled: !canDo(profileData, Actions.ManageSettings),
        },
        {
          name: "Reports",
          url: "/reports",
          iconProps: {
            iconName: "StackedColumnChart2Fill",
            styles: { root: { color: "seaGreen" } },
          },
          key: "reports",
          disabled: !canDo(profileData, Actions.Reports),
        },
        {
          name: "Analytics",
          url: "/",
          iconProps: {
            iconName: "AnalyticsReport",
            styles: { root: { color: "oliveDrab" } },
          },
          key: "analytics",
          disabled: !canDo(profileData, Actions.Analytics),
        },
        {
          name: "Import",
          key: "import",
          url: "",
          isExpanded: expandedNav === "import",
          disabled: !canDo(profileData, Actions.Import),
          links: [
            {
              name: "Assets",
              url: "/importAssets",
              icon: "ImportAllMirrored",
              key: "importAssets",
              target: "_blank",
            },
            {
              name: "Issue types",
              url: "/importIssueTypes",
              icon: "DefectSolid",
              key: "importIssueTypes",
              target: "_blank",
            },
            {
              name: "Zones",
              url: "/importZones",
              icon: "EngineeringGroup",
              key: "importZones",
              target: "_blank",
            },
          ],
        },
        {
          name: "Security",
          url: "/security",
          iconProps: {
            iconName: "Permissions",
            styles: { root: { color: "tomato" } },
          },
          key: "security",
          disabled: !canDo(profileData, Actions.Permissions),
        },
        {
          name: "Custom fields",
          url: "/metadata",
          iconProps: {
            iconName: "ProcessMetaTask",
            styles: { root: { color: "DarkSlateGray" } },
          },          
          key: "metadata",
          disabled: !canDo(profileData, Actions.ManageMetadata),
        },
        {
          name: "Settings",
          url: "/settings/client",
          icon: "Settings",
          key: "settings",
          disabled: !canDo(profileData, Actions.ManageSettings),
        },
      ],
    },
  ];

  const navStyles: Partial<INavStyles> = {
    root: {
      width: navExpanded ? 180 : 42,
      boxSizing: "border-box",
      border: "1px solid #eee",
      overflowY: "auto",
      backgroundImage: "linear-gradient(to right, #FFFFFF, #FAFAFA)",
    },
  };

  const titleTextStyles: Partial<ITextStyles> = {
    root: {
      paddingLeft: "14px",
      paddingTop: "14px",
    },
  };

  const subtitleTextStyles: Partial<ITextStyles> = {
    root: {
      paddingLeft: "5px",
    },
  };
  const logoBackground = mergeStyles({
    backgroundImage: "linear-gradient(to right, lightblue, #FFFFFF)",
  });

  const progressBarStyles: IProgressIndicatorStyles = {
    itemDescription: "",
    itemName: "",
    itemProgress: { padding: "0px 0px" },
    progressBar: "",
    progressTrack: "",
    root: {},
  };

  const [connection, setConnection] = useState<null | HubConnection>(null);
  useEffect(() => {
    const connect = new HubConnectionBuilder()
      .withUrl(`${process.env.REACT_APP_NOTIF_API_URL}`)
      .withAutomaticReconnect()
      .build();

    setConnection(connect);
  }, []);

  const setNotif = useSetAtom(notifAtom);

  useEffect(() => {
    if (connection && connection.state === HubConnectionState.Disconnected) {
      connection
        .start()
        .then(() => {
          connection.on("newMessage", (message) => {
            console.log(message);
            setNotif(JSON.parse(message) as INotifMessage);
          });
        })
        .then(async () => {
          if (connection) {
            setIsConnectedToHub(true);
            await connection.send("sendMessage", "echo");
          }
        })
        .catch((error) => {
          setIsConnectedToHub(false);
          console.log(error);
        });
    }
  }, [connection, context]);

  const notifValue = useAtomValue(notifAtom);
  const setMessageBar = useSetAtom(messageBarAtom);

  useEffect(() => {
    let message = "";
    if (notifValue.t === "WOU") {
      const woId = Number(notifValue.b);
      message = `WorkOrder ${woId} has been updated`;
    } else if (notifValue.t === "AU" || notifValue.t === "ASU") {
      const assetId = Number(notifValue.b);
      message =
        notifValue.t === "AU"
          ? `Asset ${assetId} has been updated`
          : `The status of asset ${assetId} has been changed`;
    } else if (notifValue.t === "IC") {
      const issueId = Number(notifValue.b);
      message = `New issue has been reported: (Issue id: ${issueId})`;
    }
    if (message === "") {
      return;
    }
    setMessageBar(message, MessageBarType.warning);
    if (!notifications.find((f) => f.message === message)) {
      const newNotifications = [...notifications];
      newNotifications.push({ message });
      setNotifications(newNotifications);
    }
  }, [notifValue]);

  const fetchData = async () => {
    context.setSelectedTab("Assets");

    const abortController = new AbortController();
    setIsInProgress(true);
    try {
      // const metadata = await getMetadata(abortController, 'Asset');
      // setMetadata(metadata);

      const zones = await getZones(abortController);

      setZones(zones);
      setZonesLoaded(true);

      const categories = await getAssetCategories(abortController);
      setAssetCategories(categories);

      // const assetStatuses: IAssetStatuses[] = await getAssetStatuses(abortController);
      // setAssetStatuses(assetStatuses);
    } catch (error: any) {
      console.error("Error:", error);
      setErrorMessage(error.message);
    } finally {
      setIsInProgress(false);
    }
    return () => {
      abortController.abort();
    }
  }

  useEffect(() => {
    fetchData()
  }, []);

  return isAuthenticatedAtom ? (
    <div className="App">
      <Stack
        horizontal
        styles={{
          root: {
            backgroundImage: "linear-gradient(to right, lightblue, #FFFFFF)",
          },
        }}
      >
        <Stack.Item className={logoBackground}>
          <Text variant="xxLarge" styles={titleTextStyles}>
            FMS
          </Text>
          <Text styles={subtitleTextStyles}>Fix my services</Text>
        </Stack.Item>
        <Stack.Item grow>
          <CommandBar
            className="Header"
            items={_items}
            farItems={_farItems}
            ariaLabel="Items actions"
            primaryGroupAriaLabel="Items actions"
            farItemsGroupAriaLabel="More actions"
          />
        </Stack.Item>
      </Stack>
      <Stack>
        <ProgressIndicator
          styles={progressBarStyles}
          percentComplete={!isInProgress ? 0 : undefined}
        />
        <Stack horizontal>
          <Nav
            onLinkClick={(e, link) => {
              e?.preventDefault();
              clearMessage();
              context.setSelectedItem({ id: 0, uuid: "" });
              setActiveNav(link?.key ?? "");
              if (link?.url) {
                navigate(link?.url);
              }
            }}
            selectedKey={activeNav}
            ariaLabel="Nav"
            styles={navStyles}
            groups={navLinkGroups}
            onLinkExpandClick={(_, item?: INavLink) => {
              setExpandedNav(item?.key ?? "");
            }}
          />
          <Stack grow style={{ display: "flex" }}>
            {message.message ? (
              <MessageBar
                messageBarType={message.messageType}
                isMultiline={false}
                dismissButtonAriaLabel="Close"
                onDismiss={() => clearMessage()}
                actions={
                  <div>
                    {message.actions?.map((a) => (
                      <MessageBarButton onClick={a.action}>
                        {a.actionLabel}
                      </MessageBarButton>
                    ))}
                  </div>
                }
              >
                {message.message}
              </MessageBar>
            ) : (
              <></>
            )}
            <Stack
              horizontal
              disableShrink
              tokens={{
                childrenGap: 5,
              }}
            >
              <Stack.Item align="stretch" grow>
                <Text
                  variant="xLargePlus"
                  styles={{ root: { padding: "10px 14px 25px 25px" } }}
                >
                  {context.selectedTab}
                </Text>
              </Stack.Item>
              <Stack.Item align="end">
                <MiniChart></MiniChart>
              </Stack.Item>
            </Stack>
            <Separator />
            <div
              style={{
                padding: "10px 14px 25px 25px",
                overflow: "auto",
                height: "720px",
              }}
            >
              {props.children}
            </div>
          </Stack>
        </Stack>
      </Stack>
      <InfoDialog
        isModalOpen={isAboutModalOpen}
        hideModal={hideAboutModal}
        message={
          <>
            <p>
              <img
                src={logo}
                alt="MoKo Solution logo"
                style={{
                  width: 50,
                }}
              />
            </p>
            <p>{`Portal API build number: ${buildNumber}`}</p>
            <p>{`Portal UI build number:${process.env.REACT_APP_BUILD_NUMBER}`}</p>
          </>
        }
      />
      <ChatCommandDialog
        hideDialog={hideDialog}
        toggleHideDialog={toggleHideDialog}
      />
      <MyPermissions
        isOpen={isMyPermissionsOpen}
        hideMyPermissions={hideMyPermissions}
      />
      <TenantSelector
        isOpen={isTenantSelectorOpen}
        hideTenantSelector={hideTenantSelector}
      />
      <Notifications
        isOpen={isNotificationsOpen}
        hideNotifications={hideNotifications}
      />
    </div>
  ) : (
    <>
      {message.message ? (
        <MessageBar
          messageBarType={message.messageType}
          isMultiline={false}
          dismissButtonAriaLabel="Close"
          onDismiss={() => clearMessage()}
          actions={
            <div>
              {context.actions.map((a) => (
                <MessageBarButton onClick={a.action}>
                  {a.actionLabel}
                </MessageBarButton>
              ))}
            </div>
          }
        >
          {message.message}
        </MessageBar>
      ) : (
        <></>
      )}
      <Spinner
        size={SpinnerSize.large}
        label="Authenticating..."
        ariaLive="assertive"
        labelPosition="left"
        style={showAuthSpinner ? {} : { display: "none" }}
      />
    </>
  );
};

export default Home;
