import {
  IContextualMenuItem,
  IContextualMenuListProps,
  IContextualMenuProps,
  IRenderFunction,
  ISearchBoxStyles,
  Icon,
  IconButton,
  KeyCodes,
  SearchBox,
  Stack,
  TextField,
} from "@fluentui/react";
import React, { useState } from "react";
import { useAtomValue } from "jotai";
import { zonesAtom } from "../../atoms/zonesAtom";
import { IZone } from "../../services/assetServices";
import { flattenZonesTree } from "../../common/FlattenHelper";

export interface IZoneSelectorParams {
  zoneId?: number;
  disabled: boolean;
  onSelected: (id: number) => void;
  errorMessage?: string;
}

const ZoneSelector = (props: IZoneSelectorParams) => {
  const zonesNotFlatten = useAtomValue(zonesAtom);
  const flattenList: IZone[] = [];

  flattenZonesTree(zonesNotFlatten, flattenList, '');
  const zones = flattenList;

  const [items, setItems] = useState<IContextualMenuItem[]>(
    zones?.map((cat) => ({
      key: cat?.id?.toString() ?? "",
      text: cat.name,
    })) ?? []
  );

  const filteredItemsStyle: React.CSSProperties = {
    width: "100%",
    height: "100px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  };
  const searchBoxStyles: ISearchBoxStyles = {
    root: { margin: "8px" },
  };

  const onAbort = React.useCallback(() => {
    setItems(
      zones?.map((cat) => ({
        key: cat?.id?.toString() ?? "",
        text: cat.name,
      })) ?? []
    );
  }, []);

  const onChange = React.useCallback(
    (ev?: React.ChangeEvent<HTMLInputElement>, newValue?: string) => {
      const filteredItems: IContextualMenuItem[] = !newValue
        ? zones?.map((cat) => ({
            key: cat?.id?.toString() ?? "",
            text: cat.name,
          })) ?? []
        : zones
            ?.filter(
              (cat) =>
                cat.name &&
                cat.name.toLowerCase().indexOf(newValue.toLowerCase()) !== -1
            )
            .map((cat) => ({
              key: cat?.id?.toString() ?? "",
              text: cat.name,
            })) ?? [];

      if (!filteredItems || !filteredItems.length) {
        filteredItems.push({
          key: "no_results",
          onRender: (item, dismissMenu) => (
            <div key="no_results" style={filteredItemsStyle}>
              <Icon iconName="SearchIssue" title="No actions found" />
              <span>No zones found</span>
            </div>
          ),
        });
      }

      setItems(filteredItems);
    },
    []
  );

  const onKeyDown = React.useCallback((e: any) => {
    /* Key Up, but we are not at the beginning of the text: stop event propagation to prevent ContextualMenu to focus */
    if (e.target.selectionStart > 0 && e.which === KeyCodes.up) {
      e.stopPropagation();
    }
    /* Key Down, but we are not at the end of the text: stop event propagation to prevent ContextualMenu to focus */
    if (
      e.target.selectionStart !== e.target.value.length &&
      e.which === KeyCodes.down
    ) {
      e.stopPropagation();
    }
  }, []);

  const onDismiss = React.useCallback(() => {
    setItems(
      zones?.map((cat) => ({
        key: cat?.id?.toString() ?? "",
        text: cat.name,
      })) ?? []
    );
  }, []);

  const renderMenuList = React.useCallback(
    (
      menuListProps: IContextualMenuListProps,
      defaultMenuItemRenderer: IRenderFunction<IContextualMenuListProps>
    ) => {
      return (
        <div>
          <div style={{ borderBottom: "1px solid #ccc" }}>
            <SearchBox
              ariaLabel="Filter actions by text"
              placeholder="Filter actions"
              onAbort={onAbort}
              onChange={onChange}
              onKeyDown={onKeyDown}
              styles={searchBoxStyles}
            />
          </div>
          {defaultMenuItemRenderer(menuListProps)}
        </div>
      );
    },
    [onAbort, onChange, onKeyDown]
  );

  const catItemRenderer = (catItem: any) => {
    return (
      catItem.item.text ? (
        <Stack horizontal>
          <span style={{ width: catItem.item.text.indexOf("/") * 14 }}></span>
          <span>
            {catItem.item.text.substring(catItem.item.text.indexOf("/") + 1)}
          </span>
        </Stack>
      ) : (
        <></>
      )
    );
  };

  const menuProps = React.useMemo<IContextualMenuProps>(
    () => ({
      onRenderMenuList: (
        props?: IContextualMenuListProps,
        defaultMenuItemRenderer?: IRenderFunction<IContextualMenuListProps>
      ) => {
        if (!defaultMenuItemRenderer) {
          return <></>;
        }
        return (
          <div>
            <div style={{ borderBottom: "1px solid #ccc" }}>
              <SearchBox
                ariaLabel="Filter actions by text"
                placeholder="Filter actions"
                onAbort={onAbort}
                onChange={onChange}
                onKeyDown={onKeyDown}
                styles={searchBoxStyles}
              />
            </div>
            {defaultMenuItemRenderer(props)}
          </div>
        );
      },
      shouldFocusOnMount: true,
      items: items,
      focusZoneProps: {
        shouldInputLoseFocusOnArrowKey: () =>
          true /* Allow up and down arrows to move focus out of the SearchBox */,
      },
      contextualMenuItemAs: catItemRenderer,
      onDismiss,
      onItemClick(ev, item) {
        if (!item) {
          return;
        }
        props.onSelected(Number.parseInt(item?.key));
      },
    }),
    [renderMenuList, onDismiss, zones, items]
  );

  return (
    <div style={{ position: "relative", width: 200 }}>
      <TextField
        label="Zones"
        value={
          zones?.find((zone) => zone.id === props.zoneId)?.name?.replace('—', '')
        }
      />
      <IconButton
        iconProps={{ iconName: "Search" }}
        styles={{
          root: { position: "absolute", top: 30, right: 2, height: 30 },
        }}
        menuProps={menuProps}
      />
    </div>
  );
};

export default ZoneSelector;
