import { useRef } from "react";
import EditItem from "../../common/EditItem";
import {
  createReferenceValue,
  editReferenceValue,
  getReferenceValue,
} from "../../services/assetServices";
import { useLocation } from "react-router-dom";

const ReferenceValue = () => {
  const isSaved = useRef(false);
  const query = new URLSearchParams(useLocation().search);
  const refName = query.get("refName") ?? "";
  
  return (
    <>
      <div>Reference Value</div>
      <EditItem
        getAction={(abortController, id) =>
          getReferenceValue(abortController, id)
        }
        newAction={(abortController, newReferenceValue) =>
          createReferenceValue(abortController, {
            refName: refName,
            key: newReferenceValue.key,
            value: newReferenceValue.value,
            parentRef: newReferenceValue.parentRef,
            parentKey: newReferenceValue.parentKey,
          })
        }
        editAction={(abortController, id, editedRefValue) =>
          editReferenceValue(abortController, id, {
            value: editedRefValue.value,
            parentRef: editedRefValue.parentRef,
            parentKey: editedRefValue.parentKey,
          })
        }
        isSaved={isSaved}
        back={"/settings/references"}
        metadata={{
          fields: [
            {
              name: "key",
              fieldType: "String",
              label: "Key",
            },
            {
              name: "value",
              fieldType: "String",
              label: "Value",
            },
            {
              name: "parentRef",
              fieldType: "String",
              label: "Parent Ref",
            },
            {
              name: "parentKey",
              fieldType: "String",
              label: "Parent Key",
            },
          ],
          lookups: [],
          validations: [],
        }}
      />
    </>
  );
};

export default ReferenceValue;
