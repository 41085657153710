import { appRoles } from '../../authConfig';
import PagedList, { IPagedListRefType } from '../../common/PagedList'
import { deleteWorkflow, getWorkflows } from '../../services/assetServices'
import { useAtomValue, useSetAtom } from 'jotai';
import { profileDataAtom } from "../../atoms/authAtoms";
import { useNavigate } from 'react-router-dom';
import { useRef, useState } from 'react';
import { successMessageAtom } from '../../atoms/messageBarAtoms';

const Workflows = () => {
  const profileData = useAtomValue(profileDataAtom);
  const navigate = useNavigate();
  const setSuccessMessage = useSetAtom(successMessageAtom);
  const [selectedWorkflowId, setSelectedWorkflowId] = useState<Number>();

  const pagedListRef = useRef<IPagedListRefType>(null);

  return (
    <PagedList selectedTab='Workflows'
      ref={pagedListRef}
      columns={[
        { key: 'id', minWidth: 55 },
        { key: 'name', minWidth: 200 },
        { key: 'purpose', minWidth: 200 },
      ]}
      getAction={getWorkflows}
      commandBarItems={[
        {
          key: "newWorkflow",
          text: "New",
          iconProps: { iconName: "Add" },
          onClick: () => navigate("/newWorkflow"),
          disabled: !profileData.roles.includes(appRoles.Admin)
        },
        {
          key: "editWorkflow",
          text: "Edit",
          iconProps: { iconName: "Edit" },
          onClick: () => navigate(`/editWorkflow?id=${selectedWorkflowId}`),
          disabled: !profileData.roles.includes(appRoles.Admin) || (!selectedWorkflowId)
        },
        {
          key: "deleteWorkflow",
          text: "Delete",
          iconProps: { iconName: "Delete" },
          onClick: () => {
            if (selectedWorkflowId) {
              const abortController = new AbortController();
              deleteWorkflow(abortController, (Number)(selectedWorkflowId))
                .then(() => {
                  pagedListRef.current?.refresh();
                  setSuccessMessage(`Workflow with id: ${selectedWorkflowId} deleted.`);
                })
            }
          },
          disabled: !profileData.roles.includes(appRoles.Admin) || (!selectedWorkflowId)
        },
      ]}
      onSelectionChange={(selection: any) => { setSelectedWorkflowId((Number)(selection.id)) }}
    ></PagedList>
  )
}

export default Workflows