import {
  CommandBar,
  DetailsListLayoutMode,
  IColumn,
  ICommandBarItemProps,
  IconButton,
  Link,
  mergeStyles,
  Modal,
  SearchBox,
  SelectionMode,
  Stack,
  Text,
} from "@fluentui/react";
import {
  DetailsList,
  IDetailsColumnProps,
  Selection,
} from "@fluentui/react/lib/DetailsList";
import { useBoolean } from "@fluentui/react-hooks";
import { useContext, useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { appRoles } from "../../authConfig";
import {
  scrollStackItemStyles,
  stackStyle,
} from "../../common/styles/StackStyles";
import {
  createWorkOrder,
  editIssue,
  getImage,
  getIssues,
  getMetadata,
  IField,
  IImage,
  IIssue,
  IPagedCollection,
  IWorkOrderResponse,
} from "../../services/assetServices";
import AppContext from "../AppContext";
import HeaderWithFilter from "../../common/HeaderWithFilter";
import AssetSelector from "./AssetSelector";
import {
  errorMessageAtom,
  isInProgressAtom,
  successMessageAtom,
} from "../../atoms/messageBarAtoms";
import { profileDataAtom } from "../../atoms/authAtoms";
import { useAtomValue, useSetAtom } from "jotai";
import LoadingSign from "../../common/LoadingSign";
import {
  gridStyles,
  focusZoneProps,
  gridStylesNames,
} from "../../common/styles/GridStyles";

function Issues() {
  const [issues, setIssues] = useState<IIssue[]>();
  const [isLastPage, setIsLastPage] = useState(false);
  const [pageCount, setPageCount] = useState(0);
  const [page, setPage] = useState({ no: 1, search: "", filters: "" });
  const [shimmered, setShimmered] = useState(false);
  const [currentIssue, setCurrentIssue] = useState(0);
  const [selectedIssues, setSelectedIssues] = useState<IIssue[]>([]);
  const [refreshCount, setRefreshCount] = useState(0);
  const setSuccessMessage = useSetAtom(successMessageAtom);

  const navigate = useNavigate();

  const context = useContext(AppContext);
  const setErrorMessage = useSetAtom(errorMessageAtom);
  const setIsInProgress = useSetAtom(isInProgressAtom);
  const profileData = useAtomValue(profileDataAtom);

  const _columns: IColumn[] = [
    {
      key: "Id",
      name: "Id",
      fieldName: "id",
      minWidth: 25,
      maxWidth: 50,
      isResizable: true,
    },
    {
      key: "uuid",
      name: "UUID",
      fieldName: "uuid",
      minWidth: 70,
      maxWidth: 100,
      isResizable: true,
    },
    {
      key: "Comment",
      name: "Comment",
      fieldName: "comment",
      minWidth: 250,
      maxWidth: 250,
      isResizable: true,
    },
    {
      key: "image",
      name: "Image",
      fieldName: "imageUrl",
      minWidth: 50,
      maxWidth: 50,
    },
    {
      key: "assetId",
      name: "Asset Id",
      fieldName: "assetId",
      minWidth: 50,
      maxWidth: 75,
      isResizable: true,
      onRender: (item) => (
        <Link
          key={item.assetId}
          onClick={() => navigate(`/assetDetail?itemId=${item.assetId}`)}
        >
          {item.assetId}
        </Link>
      ),
    },
    {
      key: "createdAt",
      name: "CreateAt",
      onRenderHeader: (props?: IDetailsColumnProps) => {
        return (
          <HeaderWithFilter
            columnName={props?.column.name ?? ""}
            columnDbName="CREATED_AT"
            type="D"
            onFilterSelected={(filterStr: string) =>
              setPage({ ...page, filters: filterStr })
            }
            onFilterCleared={() => setPage({ ...page, filters: "" })}
          />
        );
      },
      fieldName: "createdAt",
      minWidth: 125,
      maxWidth: 155,
      isResizable: true,
    },
    {
      key: "crmCaseId",
      name: "CRM Case Id",
      fieldName: "crmCaseId",
      minWidth: 75,
      maxWidth: 100,
      isResizable: true,
    },
    {
      key: "crmCaseStatusId",
      name: "CRM Status",
      fieldName: "crmCaseStatusId",
      minWidth: 75,
      maxWidth: 100,
      isResizable: true,
    },
  ];
  const columns = useRef<IColumn[]>(_columns);

  useEffect(() => {
    const abortController = new AbortController();

    const fetchData = async () => {
      context.setSelectedTab("Issues");
      setIsInProgress(true);

      try {
        const metadata = await getMetadata(abortController, "Issue");
        const otherInfoFields = metadata?.fields.filter((mf) => mf.showInGrid);

        const data: IPagedCollection<IIssue> = await getIssues(
          abortController,
          10,
          page.no,
          page.search,
          page.filters
        );
        const _issues = data?.items?.map((issue) => {
          const newIssue: any = {
            ...issue,
            createdAt: new Date(issue.createdAt + "Z").toLocaleString(),
          };

          const otherInfoParts = issue.otherInfo?.split("|");
          if (otherInfoParts) {
            otherInfoFields?.forEach((mf: IField, index) => {
              const part = otherInfoParts[index];
              newIssue[mf.name] = part;
            });
          }
          otherInfoFields?.forEach((mf) => {
            if (!columns.current?.find((column) => column.name === mf.name)) {
              columns.current?.push({
                key: mf.name,
                name: mf.name,
                fieldName: mf.name,
                minWidth: 100,
                onRender:
                  mf.fieldType === "Date"
                    ? (item: any) => (
                        <span>
                          {item[mf.name]
                            ? new Date(item[mf.name]).toDateString()
                            : ""}
                        </span>
                      )
                    : mf.fieldType === "AutoComplete"
                    ? (item: any) => (
                        <span>{(item[mf.name] ?? ":").split(":")[1]}</span>
                      )
                    : undefined,
              });
            }
          });

          return newIssue;
        });

        setIssues(_issues);

        setIsLastPage(data?.isLastPage);
        setPageCount(data?.pageCount);
        setShimmered(false);
      } catch (error: any) {
        console.error("Error:", error);
        setErrorMessage(error.message);
      } finally {
        setIsInProgress(false);
      }
    };

    fetchData();
    return () => {
      abortController.abort();
    };
  }, [page.no, page.search, page.filters, refreshCount]);

  const itemClass = mergeStyles({
    selectors: {
      "&:hover": {
        textDecoration: "underline",
        cursor: "pointer",
      },
    },
  });
  const [isModalOpen, { setTrue: showModal, setFalse: hideModal }] =
    useBoolean(false);
  const [
    isAssetSelectorOpen,
    { setTrue: showAssetSelector, setFalse: hideAssetSelector },
  ] = useBoolean(false);

  const handleClickOnImageLink = (issue: IIssue | undefined) => {
    if (!issue || !issues) {
      return;
    }
    const abortController = new AbortController();

    getImage(abortController, issue.imageUrl)
      .then((data: IImage) => {
        const index = issues.indexOf(issue);
        issues[index].image = data.imageContent;
        setIssues(issues);
        setCurrentIssue(index);
        showModal();
      })
      .catch((error) => {
        console.error("Error:", error);
        setErrorMessage(error.message);
      });
  };

  const handleCreateWorkOrder = async () => {
    const abortController = new AbortController();
    setIsInProgress(true);
    const issue = selectedIssues[0];
    createWorkOrder(abortController, issue.id, {
      assetId: issue.assetId,
      zoneId: issue.zoneId,
      description:
        `Generated by FMS [Comment: ${issue.comment}] ` +
        `[I${issue.id}${issue.zoneId ? `-Z${issue.zoneId}` : ""}` +
        `${issue.zoneAssetCat ? `-AC${issue.zoneAssetCat}` : ""}` +
        `${issue.issueTypeId ? `-IT${issue.issueTypeId}` : ""}]`,
      zoneAssetCatId: selectedIssues[0].zoneAssetCat,
      issueTypeId: selectedIssues[0].issueTypeId,
    })
      .then((workOrderResponse: IWorkOrderResponse) => {
        if (workOrderResponse?.workOrderId) {
          setSuccessMessage(
            `WorkOrder with id: ${workOrderResponse?.workOrderId} successfully created.`
          );
        } else {
          setErrorMessage("WorkOrder creation failed.");
        }
      })
      .catch((error) => {
        console.error("Error:", error);
        setErrorMessage(error.message);
      })
      .finally(() => setIsInProgress(false));
  };

  const _renderItemColumn = (
    item: IIssue,
    index?: number,
    column?: IColumn
  ) => {
    const fieldContent = item[column?.fieldName as keyof IIssue] as string;

    switch (column?.key) {
      case "image":
        return item.imageUrl ? (
          <Link
            className={itemClass}
            disabled={item?.imageUrl === ""}
            onClick={() => handleClickOnImageLink(item)}
          >
            image
          </Link>
        ) : (
          <span></span>
        );
      default:
        return <span>{fieldContent}</span>;
    }
  };

  const _items: ICommandBarItemProps[] = [
    {
      key: "newIssue",
      text: "New",
      iconProps: { iconName: "Add" },
      disabled: !profileData.roles.includes(appRoles.Admin),
      subMenuProps: {
        items: [
          {
            key: "newIssueByZone",
            text: "By Zone",
            iconProps: { iconName: "EngineeringGroup" },
            onClick: () => navigate("/newIssue/zone"),
          },
          {
            key: "newIssueByAsset",
            text: "By Asset",
            onClick: () => navigate("/newIssue/asset"),
          },
        ],
      },
    },
    {
      key: "editIssue",
      text: "Edit",
      iconProps: { iconName: "Edit" },
      onClick: () => navigate(`/editIssue?id=${selectedIssues[0].id}`),
      disabled:
        !profileData.roles.includes(appRoles.Admin) ||
        selectedIssues.length === 0,
    },
    {
      key: "createWorkOrder",
      text: "Create WorkOrder",
      iconProps: { iconName: "WorkItem" },
      onClick: () => handleCreateWorkOrder(),
      disabled:
        !profileData.roles.includes(appRoles.Admin) ||
        selectedIssues.length === 0,
    },
    {
      key: "setIssueAsset",
      text: "Set Asset",
      iconProps: { iconName: "Link" },
      onClick: () => {
        showAssetSelector();
      },
      disabled:
        !profileData.roles.includes(appRoles.Admin) ||
        selectedIssues.length === 0,
    },
    {
      key: "deleteIssue",
      text: "Delete",
      iconProps: { iconName: "Delete" },
      disabled: true,
      // onClick: () => {
      //   context.setSelectedItem({ id: 0, uuid: "" });
      //   navigate(`/assetDetail?action=delete&itemId=${context.selectedItem.id}`);
      // },
      // disabled: !profileData.roles.includes(appRoles.Admin) || context.selectedItem.uuid === "",
    },
  ];
  const _selection = new Selection({
    onSelectionChanged: () => {
      setSelectedIssues(
        _selection.getSelection()?.map((item) => item as IIssue)
      );
    },
  });

  return (
    <>
      <Stack>
        <Stack horizontal verticalAlign="center">
          <CommandBar
            items={_items}
            ariaLabel="Items actions"
            primaryGroupAriaLabel="Items actions"
            farItemsGroupAriaLabel="More actions"
          />
          <SearchBox
            placeholder="Search issue"
            onSearch={(newValue) =>
              setPage({ ...page, no: 1, search: newValue || "" })
            }
            onClear={() => setPage({ ...page, no: 1, search: "" })}
          />
        </Stack>
        <Stack styles={stackStyle}>
          <Stack.Item align="start" styles={scrollStackItemStyles}>
            {issues !== undefined ? (
              <DetailsList
                items={issues}
                columns={columns.current}
                setKey="set"
                layoutMode={DetailsListLayoutMode.fixedColumns}
                selection={_selection}
                onRenderItemColumn={_renderItemColumn}
                selectionMode={SelectionMode.single}
                selectionPreservedOnEmptyClick={true}
                ariaLabelForSelectionColumn="Toggle selection"
                ariaLabelForSelectAllCheckbox="Toggle selection for all items"
                checkButtonAriaLabel="select row"
                styles={gridStyles}
                focusZoneProps={focusZoneProps}
                selectionZoneProps={{
                  className: gridStylesNames.selectionZone,
                }}
              />
            ) : (
              <LoadingSign message="Loading..." iconName="IssueSolid" />
            )}
          </Stack.Item>
        </Stack>
        <Stack horizontal horizontalAlign="space-between">
          <Stack.Item grow={1} align="center">
            <IconButton
              iconProps={{ iconName: "DoubleChevronLeft" }}
              disabled={page.no === 1}
              onClick={() => setPage({ ...page, no: 1 })}
            />
            <IconButton
              iconProps={{ iconName: "ChevronLeft" }}
              disabled={page.no === 1}
              onClick={() => setPage({ ...page, no: page.no - 1 })}
            />
            <Text>
              {page.no} of {pageCount}
            </Text>
            <IconButton
              iconProps={{ iconName: "ChevronRight" }}
              disabled={isLastPage}
              onClick={() => setPage({ ...page, no: page.no + 1 })}
            />
            <IconButton
              iconProps={{ iconName: "DoubleChevronRight" }}
              disabled={isLastPage}
              onClick={() => setPage({ ...page, no: pageCount })}
            />
          </Stack.Item>
        </Stack>
      </Stack>
      <Modal isOpen={isModalOpen} onDismiss={hideModal} isBlocking={false}>
        {issues && issues[currentIssue]?.image !== undefined ? (
          <div>
            <img src={issues[currentIssue]?.image} alt="" />
          </div>
        ) : (
          <></>
        )}
      </Modal>
      <AssetSelector
        isOpen={isAssetSelectorOpen}
        hideAssetSelector={hideAssetSelector}
        onAssetLinkClick={(assetId) => {
          const abortController = new AbortController();
          const updatedIssue = { assetId: assetId };
          editIssue(abortController, selectedIssues[0].id, updatedIssue).then(
            () => {
              hideAssetSelector();
              setRefreshCount(refreshCount + 1);
            }
          );
        }}
      />
    </>
  );
}

export default Issues;
