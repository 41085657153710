import {
  CommandBar,
  Text,
  DetailsListLayoutMode,
  ICommandBarItemProps,
  IconButton,
  SearchBox,
  SelectionMode,
  ShimmeredDetailsList,
  Stack,
  IColumn,
} from "@fluentui/react";
import { Selection } from "@fluentui/react/lib/DetailsList";
import { useContext, useEffect, useImperativeHandle, useState } from "react";
import AppContext from "../components/AppContext";
import { IPagedCollection } from "../services/assetServices";
import { scrollStackItemStyles, stackStyle } from "./styles/StackStyles";
import { useSetAtom } from "jotai";
import { errorMessageAtom, isInProgressAtom } from "../atoms/messageBarAtoms";
import React from "react";

export interface IListPageColumn {
  key: string;
  name?: string;
  minWidth?: number;
  maxWidth?: number;
  isResizable?: boolean;
  onRender?: (
    item?: any,
    index?: number | undefined,
    column?: IColumn | undefined
  ) => any;
}
export interface IListPageParams {
  selectedTab: string;
  selectionMode?: SelectionMode;
  columns: IListPageColumn[];
  commandBarItems: ICommandBarItemProps[];
  getAction: (
    abortController: AbortController,
    pageSize: number,
    pageNo: number,
    orderBy?: string,
    search?: string
  ) => Promise<IPagedCollection<any>>;
  onSelectionChange?: (selection: any) => void;
  compactMode?: boolean;
  pageSize?: number;
  listHeigh?: number;
  disabled?: boolean;
}

export interface IPagedListRefType {
  refresh: () => void;
}

const PagedList: React.ForwardRefRenderFunction<
  IPagedListRefType,
  IListPageParams
> = (params, forwardedRef) => {
  useImperativeHandle(forwardedRef, () => ({ refresh }));
  const refresh = async () => {
    setRefreshCount(refreshCount + 1);
  };
  const context = useContext(AppContext);
  const setErrorMessage = useSetAtom(errorMessageAtom);
  const setIsInProgress = useSetAtom(isInProgressAtom);
  const [isLastPage, setIsLastPage] = useState(false);
  const [pageCount, setPageCount] = useState(0);
  const [page, setPage] = useState({ no: 1, search: "", filters: "" });
  const [orderBy, setOrderBy] = useState<string>();
  const [refreshCount, setRefreshCount] = useState(0);
  const [shimmered, setShimmered] = useState(false);

  const [items, setItems] = useState<any[]>();

  const fetchData = async () => {
    context.setSelectedTab(params.selectedTab);
    const abortController = new AbortController();
    setIsInProgress(true);
    try {
      const data: IPagedCollection<any> = await params.getAction(
        abortController,
        params.pageSize ?? 10,
        page.no,
        orderBy,
        page.search
      );
      setItems(data?.items);
      setIsLastPage(data?.isLastPage);
      setPageCount(data?.pageCount);
      setShimmered(false);
    } catch (error: any) {
      console.error("Error:", error);
      setErrorMessage(error.message);
    } finally {
      setIsInProgress(false);
    }
    return () => {
      abortController.abort();
    };
  };

  useEffect(() => {
    fetchData();
  }, [page.no, page.search, page.filters, refreshCount]);

  const _selection = new Selection({
    onSelectionChanged: () => {
      if (params.onSelectionChange) {
        if (_selection.getSelectedCount() > 0) {
          params.onSelectionChange(_selection.getSelection()[0]);
        }
      }
    },
    canSelectItem: (): boolean => {
      if (!!params.disabled) {
        return false;
      }
      return true;
    },
  });

  return (
    <>
      <Stack horizontal verticalAlign="center">
        <CommandBar
          items={params.commandBarItems}
          ariaLabel="Items actions"
          primaryGroupAriaLabel="Items actions"
          farItemsGroupAriaLabel="More actions"
        />
        <SearchBox
          placeholder="Search"
          onSearch={(newValue) =>
            setPage({ ...page, no: 1, search: newValue || "" })
          }
          onClear={() => setPage({ ...page, no: 1, search: "" })}
          disabled={params.disabled}
        />
      </Stack>
      <Stack
        styles={
          !params.listHeigh
            ? stackStyle
            : { ...stackStyle, root: { height: params.listHeigh } }
        }
      >
        <Stack.Item align="start" styles={scrollStackItemStyles}>
          <ShimmeredDetailsList
            items={items ?? []}
            columns={params.columns.map((c) => ({
              key: c.key,
              name: c.name ?? c.key,
              fieldName: c.key,
              minWidth: c.minWidth ?? 100,
              maxWidth: c.maxWidth ?? 125,
              isResizable: c.isResizable ?? true,
              onRender: c.onRender,
            }))}
            setKey="set"
            layoutMode={DetailsListLayoutMode.fixedColumns}
            selectionMode={params.selectionMode ?? SelectionMode.single}
            selection={_selection}
            selectionPreservedOnEmptyClick={true}
            ariaLabelForSelectionColumn="Toggle selection"
            ariaLabelForSelectAllCheckbox="Toggle selection for all items"
            checkButtonAriaLabel="select row"
            enableShimmer={shimmered}
            compact={params.compactMode}
          />
        </Stack.Item>
      </Stack>
      <Stack horizontal horizontalAlign="space-between">
        <Stack.Item grow={1} align="center">
          <IconButton
            iconProps={{ iconName: "DoubleChevronLeft" }}
            disabled={page.no === 1}
            onClick={() => setPage({ ...page, no: 1 })}
          />
          <IconButton
            iconProps={{ iconName: "ChevronLeft" }}
            disabled={page.no === 1}
            onClick={() => setPage({ ...page, no: page.no - 1 })}
          />
          <Text>
            {page.no} of {pageCount}
          </Text>
          <IconButton
            iconProps={{ iconName: "ChevronRight" }}
            disabled={isLastPage}
            onClick={() => setPage({ ...page, no: page.no + 1 })}
          />
          <IconButton
            iconProps={{ iconName: "DoubleChevronRight" }}
            disabled={isLastPage}
            onClick={() => setPage({ ...page, no: pageCount })}
          />
        </Stack.Item>
      </Stack>
    </>
  );
};

export default React.forwardRef(PagedList);
