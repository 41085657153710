import { useRef } from "react";
import EditItem from "../../common/EditItem";
import { createGroup, editGroup, getGroup } from "../../services/assetServices";
import { v4 as uuid } from "uuid";
import { useSetAtom } from "jotai";
import { successMessageAtom } from "../../atoms/messageBarAtoms";

const Group = () => {
  const isSaved = useRef(false);
  const setSuccessMessage = useSetAtom(successMessageAtom);

  return (
    <>
      <div>Group</div>
      <EditItem
        getAction={(abortController, id) => getGroup(abortController, id)}
        newAction={async (abortController, newGroup) => {
          const newGroupResponse = await createGroup(abortController, {
            name: newGroup.name,
          });
          setSuccessMessage(
            `Group with id: ${newGroupResponse.groupId} created.`
          );
        }}
        editAction={async (abortController, id, editedGroup) => {
          await editGroup(abortController, id, {
            name: editedGroup.name,
          });
          setSuccessMessage(`Group with id: ${id} successfully updated.`);
        }}
        isSaved={isSaved}
        back={"/security?tab=groups"}
        defaultValues={JSON.stringify({ uuid: uuid() })}
        metadata={{
          fields: [
            {
              name: "name",
              fieldType: "String",
              label: "Name",
            },
          ],
          lookups: [],
          validations: [],
        }}
      />
    </>
  );
};

export default Group;
